.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #2e323a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 3fr minmax(100px, 1fr);
  grid-template-rows: 200px 150px 1fr;
  height: 100%;
  width: 100%;
}

.me {
  color: yellow;
}

.button {
  cursor: pointer;
  color: #feeb53;
  text-transform: uppercase;
  background: transparent;
  padding: 20px 40px;
  border: 4px solid #feeb53;
  border-radius: 6px;
  display: inline-block;
  transition: all 1s ease;
}

.button:hover {
  color: #2e323a;
  background: #feeb53;
  border-color: #feeb53;
}

.topics {
  background-color: #1e303a;
  color: white;
  grid-row: span 3;
}

.current-topic {
  background-color: #2e313a;
  color: white;
}

.users {
  background-color: #3e323a;
  color: white;
  grid-row: span 3;
}

.time-controls {
  background-color: #4e333a;
  color: white;
}

.water-cooler {
  background-color: #5e343a;
  color: white;
}
