.topics {
  overflow: hidden;
  /* width: 100%; */
  /* height: 100%; */
  padding: 20px;

  /* display: flex; */
  /* flex-direction: column; */
}

.topic-list {
  overflow-y: scroll;
}

.topic-list::-webkit-scrollbar {
  width: 0px;
}

input {
  color: black;
  background-color: white;
  padding: 10px;
}

[contenteditable]:empty:before {
  content: attr(placeholder);
}
